<template>
  <div class="order_page">
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.Timely_Bill }}</div>
      <div style="margin-top: 30px">
        <span class="spantitle">{{ language.Billing_Type }}</span>
        <el-select v-model="payState" :placeholder="language.Payment_Status">
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-top: 30px; display: flex">
        <div>
          <span class="spantitle">{{ language.TransactionTime }}</span>
          <el-date-picker
            v-model="Select_Month"
            type="daterange"
            :range-separator="language.to"
            :start-placeholder="language.Start_Date"
            :end-placeholder="language.End_Date"
          >
          </el-date-picker>
        </div>
        <div class="input_box">
          <el-button type="primary" @click="Yesterdaybtn()">{{
            language.Yesterday
          }}</el-button>
        </div>
        <div class="input_box">
          <el-button type="primary" @click="ResetBtn()">{{ language.Reset }}</el-button>
        </div>
        <!-- <div class="input_box">
                    <el-button type="primary" @click="by()">{{language.Export}}</el-button>
                </div> -->
      </div>
      <div style="margin-top: 30px">
        <span class="spantitle">{{ language.Download_Bill }}</span>
        <el-radio-group v-model="resource">
          <el-radio label="1">{{ language.Download_in_xls_format }}</el-radio>
          <el-radio label="2">{{ language.Download_in_csv_format }}</el-radio>
        </el-radio-group>
      </div>
      <!-- <div style="padding-left: 84px;margin-top: 30px;font-size: 13px;color: #979797;">
            {{language.More_download}}
        </div> -->
      <div style="">
        <el-button
          style="width: 176px; margin-left: 84px; margin-top: 30px"
          type="primary"
          @click="Download_Bill()"
          >{{ language.Download_Bill }}</el-button
        >
      </div>
    </div>
    <!-- :name="ordername+'.xls'" -->
    <div style="display: none">
      <download-excel
        id="export-excel-wrapper"
        class="export-excel-wrapper"
        :data="json_table"
        v-if="exportexcelwrapper2"
        :fields="json_fields"
        :type="txt"
        :name="ordername"
      >
        <el-button type="success">{{ language.Download_Bill }}</el-button>
      </download-excel>
    </div>
  </div>
</template>

<script>
import payment from "../../common/json/payment.json";
import { order } from "../../api/index.js";
export default {
  data() {
    return {
      language: "",
      searchmsg: {},
      resource: "",
      options3: [
        {
          //支付状态
          value: "-1",
          label: "", //全部
        },
        {
          //支付状态
          value: "0",
          label: "", //未支付
        },
        {
          value: "1",
          label: "", //已支付未回调
        },
        {
          value: "2",
          label: "", //支付成功
        },
      ],
      json_fields: {
        // "名称": "name",//常规字段
        // "Telephone": "phone.mobile", //支持嵌套属性
      },
      json_table: [],
      json_meta: [
        [
          {
            " key ": " charset ",
            " value ": " utf- 8 ",
          },
        ],
      ],
      Select_Month: "", //选择时间
      searchtime: "", //格式时间
      payState: "", //支付状态
      Exporttype: 1,
      Exportpage: 0, //导出页数
      Exportpageing: 0, //已导出的页数
      Exporttrue: false,
      percentage: 0, //到处百分百
      exportexcelwrapper2: false,
      ordername: "",
      txt: "",
      Paymentchannellist2: [],
    };
  },
  created() {
    this.changelanguage();
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
    Exporttrue() {
      if (this.Exporttrue) {
        if (this.Exportpageing < this.Exportpage) {
          this.Exportpageing = this.Exportpageing + 1;
          this.Exporttrue = false;
          this.forExport();
        }
      }
    },
    resource() {
      this.changelanguage();
    },
  },

  methods: {
    changelanguage() {
      this.language = this.$units();
      let msg = [
        {
          label: this.language.Incompleteprocess,
          value: "-1",
        },
      ];
      this.Paymentchannellist2 = [...msg, ...payment.Paymentmethod];
      this.options3[0].label = this.language.whole; //全部
      this.options3[1].label = this.language.Unpaid; //未支付
      this.options3[2].label = this.language.Paidbutnotreceived; //已支付未回调
      this.options3[3].label = this.language.Paidandreceived; //支付成功
      // this.options3[4].label = this.language.Canceled//已取消
      if (this.resource == 1) {
        this.json_fields = {
          [this.language.Merchantordernumber]: {
            field: "mch_order_no",
            callback: (value) => {
              return "&nbsp;" + value;
            },
          },
          ["EPAY " + this.language.orderNumber]: "my_order_no",
          [this.language.TradeName]: {
            field: "order_goods_name",
            callback: (value) => {
              return "&nbsp;" + value;
            },
          },
          [this.language.Apply_No + "/appId"]: {
            field: "appid",
            callback: (value) => {
              return "&nbsp;" + value;
            },
          },
          [this.language.TransactionChannel]: "pay_from_id",
          [this.language.Amount]: "money",
          [this.language.Actualpaymentamount]: "pay_amount",
          [this.language.ServiceCharge]: "service_fee",
          [this.language.Merchantsettlementamount]: "settle_amount",
          [this.language.TransactionTime]: "create_time",
          [this.language.PaymentTime]: "pay_server_time",
          [this.language.Source]: "pay_scene",
          [this.language.State]: "pay_state",
        };
      } else if (this.resource == 2) {
        this.json_fields = {
          [this.language.Merchantordernumber]: "mch_order_no",
          ["EPAY " + this.language.orderNumber]: {
            field: "my_order_no",
            callback: (value) => {
              return "&nbsp;" + value;
            },
          },
          [this.language.TradeName]: "order_goods_name",
          [this.language.Apply_No + "/appId"]: "appid",
          [this.language.TransactionChannel]: "pay_from_id",
          [this.language.Amount]: "money",
          [this.language.ServiceCharge]: "service_fee",
          [this.language.Merchantsettlementamount]: "settle_amount",
          [this.language.TransactionTime]: "create_time",
          [this.language.PaymentTime]: "pay_server_time",
          [this.language.Source]: "pay_scene",
          [this.language.State]: "pay_state",
        };
      }
    },
    //昨天
    Yesterdaybtn() {
      let seperator1 = "-"; //格式分隔符
      let time = new Date();
      let year = time.getFullYear(); //获取完整的年份(4位)
      let month = time.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let strDate = time.getDate(); // 获取当前日(1-31)
      let currentdate = year + seperator1 + month + seperator1 + strDate;
      let timedata = new Date(currentdate).getTime() - 86400000;
      this.searchtime =
        new Date(timedata).getFullYear() +
        seperator1 +
        (Number(new Date(timedata).getMonth()) + 1) +
        seperator1 +
        new Date(timedata).getDate() +
        " 00:00:00 - " +
        new Date(timedata).getFullYear() +
        seperator1 +
        (Number(new Date(timedata).getMonth()) + 1) +
        seperator1 +
        new Date(timedata).getDate() +
        " 24:59:59";
      this.Select_Month = [
        new Date(
          new Date(timedata).getFullYear() +
            seperator1 +
            (Number(new Date(timedata).getMonth()) + 1) +
            seperator1 +
            new Date(timedata).getDate()
        ),
        new Date(
          new Date(timedata).getFullYear() +
            seperator1 +
            (Number(new Date(timedata).getMonth()) + 1) +
            seperator1 +
            new Date(timedata).getDate()
        ),
      ];
    },
    //重置
    ResetBtn() {
      this.Select_Month = [];
      this.payState = null;
      this.resource = null;
    },
    // 下载账单
    Download_Bill() {
      if (!this.payState) {
        this.$message({
          message: this.language.Pleaseselectpaymentstatus,
          type: "warning",
        });
        return;
      }
      if (!this.Select_Month) {
        this.$message({
          message: this.language.Pleaseselectatime,
          type: "warning",
        });
        return;
      }
      if (!this.resource) {
        this.$message({
          message: this.language.Pleaseselectaformat,
          type: "warning",
        });
        return;
      }
      this.searchtime =
        this.$getNowFormatDate.getNowFormatDate(this.Select_Month[0]) +
        " 00:00:00 - " +
        this.$getNowFormatDate.getNowFormatDate(this.Select_Month[1]) +
        " 23:59:59";
      this.Exportpageing = 1;
      this.Exporttype = 1;
      this.json_table = [];
      this.$message({
        message: this.language.Exportingpleasedonotjumptothepageorotheroperations,
        type: "warning",
      });
      this.forExport();
    },
    // 循环到处
    forExport() {
      let data;
      data = {
        page: this.Exportpageing,
        limit: 100,
      };
      if (this.payState >= 0) {
        data.payState = this.payState;
      }
      this.Exporttrue = false;
      if (this.searchtime) {
        data.datetime = this.searchtime;
      }
      data.isDch = 0;
      order({}, data).then((res) => {
        if (res.code == 1) {
          if (res.data.count == 0) {
            this.$message({
              message: this.language.Nodata,
              type: "warning",
            });
            return;
          }
          this.Exportpage = Math.ceil(res.data.count / 100);
          this.Exporttrue = true;
          this.percentage = (this.Exportpageing / this.Exportpage) * 100;
          for (let i = 0; i < res.data.list.length; i++) {
            if (res.data.list[i].pay_scene == 0) {
              res.data.list[i].pay_scene = this.language.Mobilewebpage;
            } else if (res.data.list[i].pay_scene == 1) {
              res.data.list[i].pay_scene = this.language.officialaccount;
            } else if (res.data.list[i].pay_scene == 2) {
              res.data.list[i].pay_scene = this.language.Applets;
            } else if (res.data.list[i].pay_scene == 3) {
              res.data.list[i].pay_scene = this.language.Computerwebsite;
            }

            if (res.data.list[i].pay_state == 0) {
              res.data.list[i].pay_state = this.language.Unpaid;
            } else if (res.data.list[i].pay_state == 1) {
              res.data.list[i].pay_state = this.language.Paidbutnotreceived;
            } else if (res.data.list[i].pay_state == 2) {
              res.data.list[i].pay_state = this.language.Paidandreceived;
            } else if (res.data.list[i].pay_state == 3) {
              res.data.list[i].pay_state = this.language.Canceled;
            }

            for (let j = 0; j < this.Paymentchannellist2.length; j++) {
              if (res.data.list[i].pay_from_id == this.Paymentchannellist2[j].value) {
                res.data.list[i].pay_from_id = this.Paymentchannellist2[j].label;
              }
            }
            res.data.list[i].money =
              res.data.list[i].money + " " + res.data.list[i].currency;
            res.data.list[i].pay_amount =
              res.data.list[i].pay_amount + " " + res.data.list[i].currency;
            res.data.list[i].service_fee =
              res.data.list[i].service_fee + " " + res.data.list[i].currency;
            if (res.data.list[i].pay_server_time) {
              res.data.list[i].pay_server_time = this.$getNowFormatDate.chagetime(
                res.data.list[i].pay_server_time
              );
            }
          }

          //
          this.json_table = [...this.json_table, ...res.data.list];
          if (this.Exportpage == this.Exportpageing) {
            if (this.resource == 1) {
              this.ordername =
                "Order" + this.$getNowFormatDate.getNowtime(new Date()) + ".xls";
              this.txt = "xls";
            } else if (this.resource == 2) {
              this.ordername =
                "Order" + this.$getNowFormatDate.getNowtime(new Date()) + ".xls";
              this.txt = "csv";
            }
            this.exportexcelwrapper2 = false;
            this.$nextTick(() => {
              this.exportexcelwrapper2 = true;
              setTimeout(() => {
                document.getElementById("export-excel-wrapper").click();
              }, 0);
            });
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.order_page {
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.page_title {
  font-size: 26px;
  font-weight: 600;
}

.spantitle {
  margin-right: 20px;
}

.input_box {
  margin-left: 20px;
}
</style>
